<template>
  <div class="list row px-0 mx-0 ">
    <div class="col-md-12">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Leita..."
          v-model="Name"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="searchName"
          >
            Leita
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="d-flex">
        <div class="p-2 align-self-center"><h4>Kundar</h4></div>

        <router-link
          to="/add-customer"
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-success  "
          >Stovna</router-link
        >

        <!-- <button
          class="ml-auto p-2 align-self-center m-3 btn btn-sm btn-danger  "
          @click="removeAllCustomers"
        >
          Rudda alt
        </button> -->
      </div>

      <ul class="list-group">
        <li
          class="list-group-item"
          :class="{ active: index == currentIndex }"
          v-for="(customer, index) in customers"
          :key="index"
          @click="setActiveCustomer(customer, index)"
        >
          {{ customer.Name }}
        </li>
      </ul>
    </div>
    <div class="col-md-4">
      <div v-if="currentCustomer">
        <h4>Kundi</h4>
        <div>
          <label><strong>Navn:</strong></label> {{ currentCustomer.Name }}
        </div>
        <div>
          <label><strong>Maskin typa:</strong></label>
          {{ currentCustomer.MachineType }}
        </div>
        <div>
          <label><strong>Kontak:</strong></label>
          {{ currentCustomer.ContactPerson }}
        </div>

        <div v-if="cases.length > 0">
          <label><strong>Kuffert:</strong></label>
          <ul class="list-group " v-if="cases.length > 0">
            <li
              v-for="(ss, index) in cases"
              :key="index"
            >
              {{ ss.Name }}
            </li>
          </ul>
        </div>

        <router-link
          :to="'/customer/' + currentCustomer.Id"
          class="badge badge-warning"
          >Broyt</router-link
        >
      </div>

      
      <!-- <div v-else>
        <br />
        <p>Please click on a Customer...</p>
      </div> -->
    </div>









    


    
  </div>
</template>

<script>
import CustomerDataService from "../../services/CustomerDataService";
import CaseDataService from "../../services/CaseDataService";

export default {
  name: "customers-list",
  data() {
    console.log('data')
    return {
      customers: [],
      currentCustomer: null,
      currentIndex: -1,
      Name: "",
      cases: []
    };
  },
  methods: {
    retrieveCases() {
      CaseDataService.getAllCustomerCases(this.currentCustomer.Id)
        .then((response) => {
          this.cases = response.data;
          //console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCustomers() {
      CustomerDataService.getAll()
        .then((response) => {
          this.customers = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveCustomers();
      this.currentCustomer = null;
      this.currentIndex = -1;
    },

    setActiveCustomer(customer, index) {
      this.currentCustomer = customer;
      this.currentIndex = customer ? index : -1;
      this.retrieveCases();
    },

    removeAllCustomers() {
      if (confirm("Are you sure you want to delete All Record!")) {
        CustomerDataService.deleteAll()
          .then((response) => {
            console.log(response.data);
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("closed");
      }
    },

    searchName() {
      CustomerDataService.findByName(this.Name)
        .then((response) => {
          this.customers = response.data;
          this.setActiveCustomer(null);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveCustomers();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
